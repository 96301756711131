import styled, { css } from "styled-components";
import icoInfo from './images/ico-info.svg';
import { colors } from './../../variables';
import { RouteComponentProps, withRouter } from "react-router-dom";

export const Catchline = styled.h1`
  position: relative;
  font-size: 38px;
  font-weight: 600;
  color: #fff;
  font-style: normal;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 38px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 32px;
  }
`;

export const LandingWrapper = styled.div`
  width: 450px;
  background-color: transparent;
  border-radius: 0px;
  color: #fff;
  position: relative;
  
  z-index: 30;
  margin-left: 80px;
  margin-top: 80px;
  @media (max-width: 991px){
    position: relative;
    margin-top: 80px;
    margin-left: inherit;
    left: inherit;
    width: auto;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
`;

export const LandingContent = styled.div`
  padding: 40px 40px;
  color: #fff;
  font-weight: normal;
  text-align: center;
  @media (max-width: 991px){
    padding: 20px 20px;
    padding-bottom: 0px;
  }
`;

export const LandingTitle = styled.div`
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;
  color: #fff;
`;

export const LandingMessage = styled.div`
  font-weight: normal;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const LandingFormContainer = styled.div`
  
`;


export const LandingForm = styled.div`
  padding: 40px;
  color: #000;
  padding-bottom: 20px;
  @media (max-width: 991px){
    button{
      width: 100%;
    }
  }
  @media (max-width: 767px){
      padding: 20px;
    }
  h3{
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
    button{
      padding: 15px 20px;
      font-size: 17px;
      margin: 0px;
      margin-top: 10px;
      width: 100%;
      text-align: center;
      justify-content: center;
      @media (max-width: 767px){
        flex: inherit;
        font-size: 16px;
        line-height: inherit;
      }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 18px 20px;
  border-radius: 5px;
  height: 14px;
  width: 100%;
  display: block;
  position: relative;
  font-size: 16px;
  background-color: #fff;
  border: 0px solid #fff;
  border-bottom: 1px solid #909090;
  color: #000;
  @media (max-width: 991px){
    border-bottom: 1px solid #fff;
  }
  &::placeholder {
    color: #909090;
    @media (max-width: 991px){
      color: #fff;
    }
  }
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 16px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 1;
  @media (max-width: 991px){
    filter: invert(1);
    opacity: 1;
  }
  cursor: pointer;
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  bottom: -70px;
  background-color: #909090;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #909090;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;


export const ImageFooter = styled.img`
  width: 100%;
  display: none;
  height: auto;
  position: relative;
  @media (max-width: 991px){
   display: block;
  }
`;