import styled from "styled-components";
import { colors } from './../../variables';

export const UploadWrapper = styled.div`
  width: 400px;
  border-radius: 0px;
  position: relative;
  margin-top: 40px;
  z-index: 30;
  left: 10%;
  @media (max-width: 991px){
    position: relative;
    border: 0px;
    margin: 0 auto;
    margin-top: 80px;
    width: auto;
    left: inherit;
    background-color: transparent;
  }
  @media (max-width: 767px){
    position: relative;
  }
`;

export const UploadContent = styled.div`
  padding: 40px 20px;
  color: #fff;
  text-align: center;
  @media (max-width: 991px){
    color: #fff;
  }
  @media (max-width: 767px){
    padding: 20px;
    padding-bottom: 50px;
  }
`;

export const UploadGif = styled.div`
  text-align: center;
  img{
    width: 120px;
    height: auto;
    filter: invert(1);
    @media (max-width: 991px){
      
    }
  }
`;

export const UploadLoader = styled.div`
  font-size: 70px;
  color: #fff;
  @media (max-width: 991px){
    color: #fff;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadLoaderText = styled.div`
  font-size: 18px;
  color: #fff;
  @media (max-width: 991px){
    color: #fff;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadTitle = styled.div`
  margin-top: 20px;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  font-style: normal;
  text-align: center;
  @media (max-width: 991px){
    color: #fff;
    font-size: 26px;
  }
`;

export const UploadMessage = styled.div`
  text-transform: normal;
  font-size: 12px;
  margin-top: 20px;
  color: #fff;
  @media (max-width: 991px){
    color: #fff;
  }
`;