import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import backgroundDesktop from './images/background-desktop.jpg';
import backgroundMobile from './images/background-mobile.jpg';

export const MainWrapper = styled.div`
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VideoBackground = styled.video`
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
  @media (max-width: 1400px){
    
  }
  @media (max-width: 991px){
    display: none;
  }
`;
const _PictureBackground = styled.div<RouteComponentProps>`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-color: #fff;
    background-size: cover;
    background-position: center center;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    @media (max-width: 991px){
      background-position: top center;
      background-image: url(${backgroundMobile});
    }
    ${props => (
      props.match.path.startsWith('/t') ||
      props.match.path.startsWith('/e') ||
      props.match.path.startsWith('/b')
    ) && css`
      background-image: url(${backgroundDesktop});
    `
    }
`;

const _VideoBackgroundMobile = styled.div<RouteComponentProps>`
  position: absolute;
  background-size: cover;
  background-position: bottom center;
  background-color: #fff;
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
    height: auto;
  min-width: 100%;
    min-height: 100%;
  object-fit: cover;
  @media (min-width: 992px){
    display: none;
  }
`;

export const PictureBackground = withRouter(_PictureBackground);
export const VideoBackgroundMobile = withRouter(_VideoBackgroundMobile);