import { RouteComponentProps, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import icoArrowDown from './images/ico-down.svg';
import icoArrowDownBlack from './images/ico-down-black.svg';

export const LanguagesWrapper = styled.div`
  position: fixed;
  right: 40px;
  top: -10px;
  z-index: 50;
  @media (max-width: 991px){
    left: 0px;
    top: 20px;
    right: inherit;
    position: absolute;
    z-index: 40;
  }
`;

export const LanguagesSelectElement = styled.div`
  width: 100%;
  margin: 5px;
  @media (max-width: 767px){
    margin-bottom: 15px;
  }
  &:first-child{
    margin-left: 0px;
  }
  &:last-child{
    margin-right: 0px;
  }
`;
const _LanguagesSelect = styled.select<RouteComponentProps>`
    background-size: 11px auto;
    background-position: 92%;
    background-image: url(${icoArrowDown});
    background-repeat: no-repeat;
    background-color: transparent;
    color: #fff;
    border-color: transparent;
    width: 60px;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 50px;
    font-size: 10px;
    position: relative;
    text-transform: uppercase;
    padding: 12px 10px 12px 20px;
    @media (max-width: 991px){
      font-size: 12px;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${icoArrowDown});
    }

    ${props => (
      props.match.path.startsWith('/test')
    ) && css`
      @media (max-width: 991px){
        color: #000;
        background-image: url(${icoArrowDownBlack});
      }
      
    `
    }
`;
export const LanguagesOption = styled.option`
  color: #000;
`;

export const LanguagesSelect = withRouter(_LanguagesSelect);